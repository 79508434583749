import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In this documentation, we will explore basic certificate operations using the `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-cli"
      }}><inlineCode parentName="a">{`step`}</inlineCode></a>{` command-line tool.
`}<inlineCode parentName="p">{`step`}</inlineCode>{` acts as a front-end interface to certificate manager and is used for many common crypto and X.509 operations.
This tutorial covers:`}</p>
    <ol>
      <li parentName="ol">{`Creating a certificate`}</li>
      <li parentName="ol">{`Inspecting a certificate`}</li>
      <li parentName="ol">{`Renewing a certificate`}</li>
      <li parentName="ol">{`Revoking a certificate`}</li>
    </ol>
    <Alert severity="info" mdxType="Alert">
  <div>
    See Smallstep certificate Manager in action. Watch the <a href="https://u.step.sm/how-to-CM-first-certificate">how-to issue your first certificate video</a>.
  </div>
    </Alert>
    <h3>{`Before you begin`}</h3>
    <p>{`This tutorial assumes you have created a Smallstep Team and a Certificate Manager Authority using the steps in `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/certificate-manager/getting-started"
      }}>{`Getting Started`}</a>{`.`}</p>
    <h3>{`step 1 - Create a test certificate`}</h3>
    <p>{`With an Authority created and your local client configured,
you can create a test certificate by running the `}<inlineCode parentName="p">{`step ca certificate`}</inlineCode>{` command.
Here is an example:`}</p>
    <CodeBlock language="shell-session" copyText="step ca certificate myservice myservice.crt myservice.key --san myservice.internal.mycompany.net --not-after 24h" mdxType="CodeBlock">
  {`$ step ca certificate myservice myservice.crt myservice.key --san myservice.internal.mycompany.net --not-after 24h`}
    </CodeBlock>
    <p>{`In this command, we are asking the CA to create a certificate with the following properties`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`myservice`}</inlineCode>{` - The certificate's subject`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`myservice.crt`}</inlineCode>{` - Save the certificate in a file with this name`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`myservice.key`}</inlineCode>{` - Save the key in a file with this name`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`--san myservice.internal.mycompany.net`}</inlineCode>{` - Add an additional SAN to the certificate, with the specified value`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`--not-after 24h`}</inlineCode>{` - Set the certificate to expire after 24 hours`}</li>
    </ul>
    <p>{`When you run this command, it will envoke the `}<inlineCode parentName="p">{`authority-admin`}</inlineCode>{` provisioner and start a single sign-on flow via the smallstep dashboard.
After a successful sign-in, the authority will issue the certificate. `}</p>
    <h3>{`Step 2 - Inspect a test certificate`}</h3>
    <p>{`You can inspect your certificate by running:`}</p>
    <CodeBlock language="shell-session" copyText="step certificate inspect --short myservice.crt" mdxType="CodeBlock">
      {`$ step certificate inspect --short myservice.crt`}
    </CodeBlock>
    <p>{`It should look similar to this.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`X.509v3 TLS Certificate (ECDSA P-256) [Serial: 2441...2018]
  Subject:     myservice
               admin@yourco.com
               https://auth.smallstep.com#98496ed4-7f27-4367-b7a2-ef828e0a4eda
  Issuer:      Dev Intermediate CA
  Provisioner: authority-admin [ID: 909d...8521]
  Valid from:  2021-10-04T21:30:12Z
          to:  2021-10-05T21:31:12Z
`}</code></pre>
    <h3>{`step 3 - renew a certificate`}</h3>
    <p>{`Certificates expire.
Certificate Manager makes renewing a certificate ahead of expiration easy.
Renewals are authenticated using your existing certificate and produce an identical certificate with a new serial number and extended lifetime.
The private key is unchanged.`}</p>
    <p>{`In its most primitive form, renewal is a simple single-command operation:`}</p>
    <CodeBlock language="shell-session" copyText="step ca renew myservice.crt myservice.key" mdxType="CodeBlock">
  {`$ step ca renew myservice.crt myservice.key`}
    </CodeBlock>
    <p>{`More than a dozen `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/renew"
      }}>{`command-line flags`}</a>{` make `}<inlineCode parentName="p">{`step ca renew`}</inlineCode>{` flexible and easy to integrate into almost any operational environment.`}</p>
    <p>{`Inspect your certificate again, and you will see it has an extended lifetime. `}</p>
    <CodeBlock language="shell-session" copyText="step certificate inspect --short myservice.crt" mdxType="CodeBlock">
      {`$ step certificate inspect --short myservice.crt
X.509v3 TLS Certificate (ECDSA P-256) [Serial: 2441...2018]
  Subject:     myservice
               admin@yourco.com
               https://auth.smallstep.com#98496ed4-7f27-4367-b7a2-ef828e0a4eda
  Issuer:      Dev Intermediate CA
  Provisioner: authority-admin [ID: 909d...8521]
  Valid from:  2021-10-04T21:32:34Z
          to:  2021-10-05T21:33:34Z`}
    </CodeBlock>
    <h3>{`step 4 - revoke a certificate`}</h3>
    <p>{`Certificate Manager uses passive revocation by default.
To passively revoke a certificate means to block its renewal at the CA.
This eliminates the additional network request that occurs when using active revocation because the certificate expires by itself.
With passive revocation, certificates cannot be immediately revoked.
Therefore, certificates should have a shorter life to reduce the value of a key that has been exfiltrated.
To revoke a certificate, run the following operation.`}</p>
    <CodeBlock language="shell-session" copyText="step ca revoke --cert=myservice.crt --key=myservice.key" mdxType="CodeBlock">
      {`$ step ca revoke --cert=myservice.crt --key=myservice.key
  ✔ CA: https://your.authority.ca.smallstep.com
Certificate with Serial Number 215963883671773554731824269659406308792 has been revoked.`}
    </CodeBlock>
    <p>{`Upon expiry, this certificate will no longer be valid.
If you try to renew the certificate, it will fail.`}</p>
    <CodeBlock language="shell-session" copyText="step ca renew myservice.crt myservice.key" mdxType="CodeBlock">
      {`$ step ca renew myservice.crt myservice.key
  error renewing certificate: The request lacked necessary authorization to be completed. Please see the certificate authority logs for more info.`}
    </CodeBlock>
    <p>{`Passive revocation doesn't use Certificate Revocation List (CRL) and Online Certificate Signing Protocol (OCSP) like Web PKI.
Certificate Manager support for CRL and OCSP is coming soon.`}</p>
    <Alert severity="info" mdxType="Alert">
  <div>
    Need help with Certificate Operations? <a href="mailto:support@smallstep.com">Contact</a> our Customer Success team.
  </div>
    </Alert>
    <h2>{`Next Steps`}</h2>
    <ul>
      <li parentName="ul">{`You've now mastered the art of basic certificate operations. It's time to explore `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/certificate-manager/custom-certs"
        }}>{`customizing certificates`}</a>{`.`}</li>
      <li parentName="ul">{`Ready to dive into a specific technology? Check out our `}<a parentName="li" {...{
          "href": "https://smallstep.com/practical-zero-trust"
        }}>{`practical zero trust project`}</a>{`. It provides step-by-step instructions for configuring TLS for popular technologies in Linux, Docker, and Kubernetes. `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      